import React, { useState } from 'react'
import { Typography, Box, Grid } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import cm from 'classnames'
import { CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'

const useStyles = makeStyles(theme => ({
  element: {
    padding: '10px',
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  elementFocused: {
    padding: '9px',
    border: `2px solid black`,
  },
  elementError: {
    padding: '9px',
    border: `2px solid ${theme.palette.error.main}`,
  },
}))

export default function StripePaymentMethodElement ({ CardElement, CardNumberElement }) {
  const theme = useTheme()
  const classes = useStyles()

  const [errorMessage, setErrorMessage] = useState()
  const [focus, setFocus] = useState(0)

  const handleChange = ({ error }) => {
    if (errorMessage && !error) {
      setErrorMessage()
    }
    if (error) {
      setErrorMessage(error.message)
    }
  }

  const renderCardElement = () => {
    return (
      <Box
        className={cm(classes.element, {
          [classes.elementFocused]: focus === 1,
          [classes.elementError]: !!errorMessage,
        })}
      >
        <CardElement
          onChange={handleChange}
          options={{
            style: {
              base: {
                fontSize: '16px',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
              },
              invalid: {
                color: theme.palette.error.main,
              },
            },
          }}
          onFocus={() => setFocus(1)}
          onBlur={() => setFocus(0)}
        />
      </Box>
    )
  }

  const renderIndividualElements = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            className={cm(classes.element, {
              [classes.elementFocused]: focus === 1,
              [classes.elementError]: !!errorMessage,
            })}
          >
            <CardNumberElement
              onChange={handleChange}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  },
                  invalid: {
                    color: theme.palette.error.main,
                  },
                },
              }}
              onFocus={() => setFocus(1)}
              onBlur={() => setFocus(0)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={cm(classes.element, {
              [classes.elementFocused]: focus === 2,
              [classes.elementError]: !!errorMessage,
            })}
          >
            <CardCvcElement
              onChange={handleChange}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  },
                  invalid: {
                    color: theme.palette.error.main,
                  },
                },
              }}
              onFocus={() => setFocus(2)}
              onBlur={() => setFocus(0)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={cm(classes.element, {
              [classes.elementFocused]: focus === 3,
              [classes.elementError]: !!errorMessage,
            })}
          >
            <CardExpiryElement
              onChange={handleChange}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                  },
                  invalid: {
                    color: theme.palette.error.main,
                  },
                },
              }}
              onFocus={() => setFocus(3)}
              onBlur={() => setFocus(0)}
            />
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {CardElement && renderCardElement()}
      {!CardElement && renderIndividualElements()}

      {errorMessage && (
        <Typography variant='caption' color='error'>
          {errorMessage}
        </Typography>
      )}
    </>
  )
}
