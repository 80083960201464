import React, { Component } from 'react'
import { Provider, connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import LogRocket from 'logrocket'

import { createBrowserHistory } from 'history'

import ScrollToTop from 'components/scrollToTop/ScrollToTop'
import Sticky from 'components/newOrderWorkflow/shared/Sticky'
import AppErrorPage from 'components/appErrorPage/AppErrorPage'
import StoreSearchPage from 'components/newOrderWorkflow/storeSearchPage/StoreSearchPage'
import SplashPage from 'components/splashPage/SplashPage'
import GroupOrder from 'components/groupOrder/GroupOrder'
import GroupOrderPrintPage from 'components/groupOrderPrintPage/GroupOrderPrintPage'
import GroupLoginPage from 'components/groupLoginPage/GroupLoginPage'
import AttendeeToken from 'components/tokenAttendee/AttendeeToken'
import AwShucksPage from 'components/awShucksPage/AwShucksPage'
import AuthProvider, { AuthContext } from 'components/context/Auth'
import store from 'redux/store'
import { setCurrentUser, setIsLoadingUser, getUserProfile } from 'redux/modules/user'
import { getConfig } from 'redux/modules/config'
import { getSupportedLocation } from 'redux/modules/location'
import {
  ATTENDEE_TOKEN_PATH,
  GROUP_ORDER_PATH,
  ERROR_PATH,
  STORES_PATH,
  SPLASH_PATH,
  STORES_FOR_LOCATION_PATH,
  STORES_FOR_NO_LOCATION_PATH,
  AW_SHUCKS_PATH,
  PAGE_NOT_FOUND,
  LOGIN_PATH,
  GROUP_ORDER_PRINT_PATH,
} from 'routes'
import Router from 'router'
import { toDisplayName } from 'utils/user'
import { pushGTMUserInfo } from './utils/gtmUtils'
import StorePage from 'components/StorePage'
import PrivateRoute from 'components/PrivateRoute'
import { selectIsLoadingUser } from 'redux/selectors'
import 'app.css'

import '@foodsby/nutrient-v3/src/css/app.css'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './createMuiTheme'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

var history = createBrowserHistory()

history.listen(location => {
  window.ga('set', 'page', location.pathname + location.search)
  window.ga('send', 'pageview')
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

class App extends Component {
  render () {
    if (this.props.isLoadingUser) {
      return null
    }
    return (
      <Elements stripe={stripePromise}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <ScrollToTop>
              <Sticky>
                <Switch>
                  <Route path={STORES_PATH} exact component={StorePage} />
                  <Route path={STORES_FOR_NO_LOCATION_PATH} exact component={StoreSearchPage} />
                  <Route path={STORES_FOR_LOCATION_PATH} exact component={StoreSearchPage} />
                  <Route path={SPLASH_PATH} exact component={SplashPage} />
                  <PrivateRoute
                    path={GROUP_ORDER_PRINT_PATH}
                    exact
                    component={GroupOrderPrintPage}
                  />
                  <Route path={GROUP_ORDER_PATH} component={GroupOrder} />
                  <Route path={ATTENDEE_TOKEN_PATH} component={AttendeeToken} />
                  <Route path={AW_SHUCKS_PATH} exact component={AwShucksPage} />
                  <Route path={LOGIN_PATH} component={GroupLoginPage} />
                  <Route path={ERROR_PATH} component={AppErrorPage} />
                  <Route
                    path={PAGE_NOT_FOUND}
                    render={props => <AppErrorPage error={{ code: 404 }} />}
                  />
                  <Route render={props => <AppErrorPage error={{ code: 404 }} />} />
                  <Redirect to={STORES_PATH} />
                </Switch>
              </Sticky>
            </ScrollToTop>
          </Router>
        </ThemeProvider>
      </Elements>
    )
  }

  componentWillReceiveProps (props) {
    const { user, loading } = props

    if (user && user.userId && this.props.user.userId !== user.userId) {
      this.initCurrentUser(user)
      this.props.getSupportedLocation(user.locationId)
    }
    this.props.setIsLoadingUser(loading)
  }

  componentDidMount () {
    const { user, loading } = this.props

    if (user && user.userId) {
      this.initCurrentUser(user)
      this.props.getSupportedLocation(user.locationId)
    }

    this.props.setIsLoadingUser(loading)
    this.props.getConfig()
  }

  initCurrentUser (currentUser) {
    this.props.setCurrentUser(currentUser)
    currentUser && currentUser.userId && this.props.getUserProfile(currentUser.userId)

    if (window && window.zE) {
      //zendesk widget identify
      window.zE('webWidget', 'identify', {
        name: currentUser.firstName ? toDisplayName(currentUser) : null,
        email: currentUser.emailAddress,
      })

      // zendesk widget prefill
      window.zE('webWidget', 'prefill', {
        name: {
          value: currentUser.firstName ? toDisplayName(currentUser) : null,
          readOnly: false,
        },
        email: {
          value: currentUser.emailAddress,
          readOnly: false,
        },
      })
    }

    if (process.env.REACT_APP_LOGROCKET_ID) {
      LogRocket.identify(currentUser.userId, {
        name: toDisplayName(currentUser),
        email: currentUser.emailAddress,
        locationId: currentUser.locationId,
      })
    }
    pushGTMUserInfo()
  }
}

App.defaultProps = {
  user: {},
}

const mapDispatchToProps = {
  setCurrentUser,
  getUserProfile,
  setIsLoadingUser,
  getConfig,
  getSupportedLocation,
}

const withAuth = WrappedComponent => props => (
  <AuthProvider>
    <AuthContext.Consumer>
      {({ logIn, loading, accessToken, user, profile, error }) => (
        <WrappedComponent {...props} {...{ logIn, loading, accessToken, error, profile, user }} />
      )}
    </AuthContext.Consumer>
  </AuthProvider>
)

const withStore = WrappedComponent => props => (
  <Provider store={store}>
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  </Provider>
)

// This is a way to handle all errors in the app.
class ErrorBoundary extends Component {
  render () {
    const { children } = this.props
    return children
  }
}

const mapStateToProps = state => ({
  isLoadingUser: selectIsLoadingUser(state),
})

export default withStore(
  withAuth(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(App),
  ),
)
