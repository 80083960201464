import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import appboy from 'appboy-web-sdk' // Braze used to be called appboy

import App from './App'
import { logException } from './utils/errorUtils'

// Setup appboy
appboy.initialize(process.env.REACT_APP_BRAZE_API_KEY, {
  baseUrl: 'https://rest.iad-03.braze.com/api/v3',
  enableLogging: process.env.NODE_ENV !== 'production',
  enableHtmlInAppMessages: true,
  minimumIntervalBetweenTriggerActionsInSeconds: 10,
  safariWebsitePushId: 'web.com.foodsby.order',
})
appboy.display.automaticallyShowNewInAppMessages()
appboy.openSession()
window.appboy = appboy
setInterval(() => appboy.requestContentCardsRefresh(), 15000)
if (process.env.NODE_ENV !== 'production') {
  appboy.stopWebTracking()
}

try {
  // set up logrocket
  if (process.env.REACT_APP_LOGROCKET_ID) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
      network: {
        requestSanitizer: req => {
          // if the url contains 'ignore'
          if (req.url.indexOf('rest.iad-03.braze.com/api') !== -1) {
            // ignore the request response pair
            return null
          }
          if (req.headers['Authorization']) {
            // remove token
            req.headers['Authorization'] = 'REDACTED'
          }
          if (req.url.indexOf('/oauth/token') > -1 || req.url.indexOf('/uaa/passwords') > -1) {
            req.body = 'REDACTED'
          }
          return req
        },
        responseSanitizer: res => {
          if (res.url.indexOf('/oauth/token') > -1 || res.url.indexOf('/uaa/passwords') > -1) {
            // Remove token from user res
            res.body = 'REDACTED'
          }
          return res
        },
      },
      console: {
        shouldAggregateConsoleErrors: true,
      },
    })
    setupLogRocketReact(LogRocket)
  }

  ReactDOM.render(<App />, document.getElementById('root'))
} catch (error) {
  logException(error)
}
