import React, { useRef } from 'react'
import { connect } from 'react-redux'
import size from 'lodash/size'
import ReactToPrint from 'react-to-print'

import StickySidebar from 'components/newOrderWorkflow/shared/stickySidebar/StickySidebar'
import Payment from 'components/newOrderWorkflow/shared/payment/Payment'
import OrderTotal from 'components/newOrderWorkflow/orderTotal/OrderTotal'
import { GroupOrderPrintReceipt } from 'components/groupOrderPrintPage/GroupOrderPrintPage'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import { selectGroupOrder } from 'redux/selectors'
import { getPayments } from 'redux/modules/user'
import { isEditable } from 'utils/order'
import { fromCents } from 'utils/money'

const OrderManageSidebar = ({ groupOrder, paymentError }) => {
  const componentRef = useRef()

  if (!groupOrder) {
    return null
  }

  const { id, attendees, budgetPerAttendeeInCents, pricing, coupon, dropoff } = groupOrder

  if (!pricing) {
    return null
  }

  const { profileData } = pricing

  const readOnly = !isEditable(groupOrder)
  const hasBudget = !!pricing.budgetedCost

  let cost
  if (readOnly || !hasBudget) {
    // Display actual cost if it's read only OR there is no budget
    cost = pricing.actualCost
  } else {
    cost = pricing.budgetedCost
  }

  if (coupon) {
    coupon.valid = true
  }

  return (
    <StickySidebar className='order-manage-sidebar dfo-sidebar'>
      <h1 className='dfo-h1 order-manage-sidebar__title'>Order #{id}</h1>

      <OrderTotal
        attendeesCount={size(attendees)}
        deliveryFee={fromCents(cost.deliveryFeeInCents)}
        coupon={coupon}
        costPerAttendee={budgetPerAttendeeInCents}
        mealTotal={fromCents(cost.foodCostInCents)}
        couponDiscount={fromCents(cost.couponDiscountInCents)}
        orderFee={fromCents(cost.storeMinimumFeeInCents)}
        taxes={fromCents(cost.totalTaxesInCents)}
        total={fromCents(cost.totalInCents)}
        dropoff={dropoff}
        orderComplete={readOnly}
        hideMealTotalTooltip={!hasBudget}
      />

      <Payment profileData={profileData} orderComplete={readOnly} paymentError={paymentError} />

      {readOnly && !paymentError && (
        <>
          <ReactToPrint
            trigger={() => <RoundButton>Print Receipt</RoundButton>}
            content={() => componentRef.current}
          />
          <div style={{ display: 'none' }}>
            <GroupOrderPrintReceipt ref={componentRef} groupOrderId={id} />
          </div>
        </>
      )}
    </StickySidebar>
  )
}

const mapStateToProps = state => ({
  groupOrder: selectGroupOrder(state),
})

const mapDispatchToProps = {
  getPayments,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderManageSidebar)
