import React from 'react'
import { connect } from 'react-redux'

import { Box, Typography } from '@material-ui/core'
import StickySidebar from 'components/newOrderWorkflow/shared/stickySidebar/StickySidebar'
import DfoSubmitButton from 'components/newOrderWorkflow/shared/DfoSubmitButton'
import Alert from 'components/alert/Alert'
import { AlertErrorMessage } from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import CouponDiscount from 'components/newOrderWorkflow/couponDiscount/CouponDiscount'
import OrderTotal from 'components/newOrderWorkflow/orderTotal/OrderTotal'
import values from 'lodash/values'
import { selectCurrentUser, newGroupOrder } from 'redux/selectors'
import { clearCoupon } from 'redux/modules/newGroupOrder'
import { calculateCartTotals, calculateBackupMealTotal } from 'utils/order'
import { ignoreOnEnter } from 'utils/inputs'

const CheckoutSidebar = ({
  budgetPerAttendeeInCents,
  noBudget,
  attendeesCount,
  defaultMeals,
  currentLocation,
  dropoff,
  currentStore,
  taxes,
  coupon,
  hasDateError,
  clearCoupon,
  applyingDiscount,
  onApplyDiscount,
  isValid,
  submitCount,
  errors,
}) => {
  if (!(attendeesCount && currentStore && currentLocation && dropoff && defaultMeals && taxes)) {
    return null
  }

  const {
    actualMealTotal,
    actualTaxes,
    actualTotal,
    actualCouponDiscount,
    actualOrderFee,
    budgetedMealTotal,
    budgetedTaxes,
    budgetedTotal,
    budgetedCouponDiscount,
    deliveryFee,
    budgetedOrderFee,
  } = calculateCartTotals({
    attendeesCount,
    budgetPerAttendeeInCents,
    coupon,
    currentStore,
    defaultMeals,
    taxes,
  })

  const backupMealCost = calculateBackupMealTotal(defaultMeals)

  return (
    <StickySidebar className='dfo-sidebar checkout-sidebar'>
      {hasDateError && (
        <Alert type='error'>
          <h2>Date/Time Failed</h2>
          You're too late! The date and time you've selected is no longer available. Please go back
          and select a new date or time for your order.
        </Alert>
      )}

      <h1 className='dfo-h1'>Ready to place your order?</h1>

      <div className='checkout-sidebar__coupon-discounts'>
        <div className='checkout-sidebar__coupon-discounts-title'>Discounts</div>
        <CouponDiscount
          coupon={coupon}
          onApply={value => onApplyDiscount(value)}
          onRemove={value => clearCoupon()}
          applying={applyingDiscount}
          onEnter={e => ignoreOnEnter(e, e => onApplyDiscount(e.target.value))}
        />
      </div>

      <OrderTotal
        attendeesCount={attendeesCount}
        deliveryFee={deliveryFee}
        coupon={coupon}
        costPerAttendee={
          noBudget && backupMealCost ? backupMealCost.value : budgetPerAttendeeInCents
        }
        noBudget={noBudget}
        mealTotal={noBudget ? actualMealTotal : budgetedMealTotal}
        couponDiscount={noBudget ? actualCouponDiscount : budgetedCouponDiscount}
        orderFee={noBudget ? actualOrderFee : budgetedOrderFee}
        taxes={noBudget ? actualTaxes : budgetedTaxes}
        total={noBudget ? actualTotal : budgetedTotal}
        dropoff={dropoff}
      />

      <DfoSubmitButton label='Place Order' />
      <Box mt={3}>
        <Typography color='textSecondary' variant='caption'>
          This site is protected by reCAPTCHA and the Google{' '}
          <a style={{ color: 'black' }} href='https://policies.google.com/privacy'>
            Privacy Policy
          </a>{' '}
          and{' '}
          <a style={{ color: 'black' }} href='https://policies.google.com/terms'>
            Terms of Service
          </a>{' '}
          apply.
        </Typography>
      </Box>

      {!isValid && submitCount > 0 && (
        <AlertErrorMessage>
          <Box>
            {values(errors).map(val => (
              <Typography>{val}</Typography>
            ))}
          </Box>
        </AlertErrorMessage>
      )}
    </StickySidebar>
  )
}

const mapDispatchToProps = {
  clearCoupon,
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  currentLocation: newGroupOrder.selectLocation(state),
  currentStore: newGroupOrder.selectStore(state),
  budgetPerAttendeeInCents: newGroupOrder.selectBudgetPerAttendeeInCents(state),
  noBudget: newGroupOrder.selectNoBudget(state),
  attendeesCount: newGroupOrder.selectAttendeesCount(state),
  defaultMeals: newGroupOrder.selectDefaultMeal(state),
  dropoff: newGroupOrder.selectDropoff(state),
  taxes: newGroupOrder.selectTaxes(state),
  coupon: newGroupOrder.selectCoupon(state),
  hasDateError: newGroupOrder.selectHasDateError(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutSidebar)
