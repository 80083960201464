import {
  parse,
  parseISO,
  format,
  formatDistanceToNow,
  addMinutes,
  addDays,
  addHours,
  startOfDay,
  isBefore,
  isAfter,
  isEqual,
  isSameDay,
  isDate,
  isValid,
} from 'date-fns'
import times from 'lodash/times'

const normalizeTimeString = timeString =>
  parseISO(timeString.includes('T') ? timeString : `1970-01-01T${timeString}`)

const ordinalSuffixOf = number => {
  const j = number % 10
  const k = number % 100

  if (j === 1 && k !== 11) {
    return 'st'
  } else if (j === 2 && k !== 12) {
    return 'nd'
  } else if (j === 3 && k !== 13) {
    return 'rd'
  } else {
    return 'th'
  }
}

export const convertTimeToDate = (timeString, date) => {
  const formattedDate = format(new Date(date), 'yyyy-MM-dd')
  return parseISO(timeString.includes('T') ? timeString : `${formattedDate}T${timeString}`)
}

export const isDateInRange = (value, start, end) =>
  (isBefore(value, end) || isEqual(value, end)) && (isAfter(value, start) || isEqual(value, start))

export const formatTime = timeString => {
  const date = normalizeTimeString(timeString)
  return date.getMinutes() === 0 ? format(date, 'ha') : format(date, 'h:mm a')
}

export const formatTimeRange = (start, end) => `${formatTime(start)}-${formatTime(end)}`

export const formatDateShort = dateString => format(new Date(dateString), 'MM/dd/yyyy')

export const formatDate = dateString => format(parseISO(dateString), 'MMMM d, yyyy')

export const formatDateTimeAsParts = dateString => {
  if (!dateString) {
    return {}
  }
  let date
  if (isDate(dateString)) {
    date = dateString
  } else {
    date = parseISO(dateString)
  }
  const dayOfWeek = date.getDate()

  return {
    date: format(date, 'eeee, MMM d'),
    dayOrdinal: ordinalSuffixOf(dayOfWeek),
    time: format(date, 'h:mm'),
    meridiem: format(date, 'a'),
  }
}

export const formatDateTime = dateString => format(parseISO(dateString), "EEEE, MMM do 'at' h:mm a")

export const formatDateTimeNoWeekday = dateString =>
  format(parseISO(dateString), "MMM do 'at' h:mm a")

export const formatCountDown = dateString => formatDistanceToNow(parseISO(dateString))

export const formatDateLongWithoutYear = dateString => format(parseISO(dateString), 'EEEE, MMMM do')

export const formatDateWithoutYear = dateString => format(parseISO(dateString), 'MMMM d')

export const formatDateShortWithoutYear = dateString => format(parseISO(dateString), 'EEE, MMM do')

export const fromDateTime = (date, time) => {
  if (!date || !time) return null
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
  )
}

export const setToToday = date => {
  date = new Date(date)
  const today = new Date()
  today.setHours(date.getHours())
  today.setMinutes(date.getMinutes())
  today.setSeconds(date.getSeconds())
  today.setMilliseconds(date.getMilliseconds())

  return today
}

// Magic number alert: we designate midnight as the "null" dropoff time
export const isTimeSet = dropoff => {
  if (!dropoff) return false
  return !(
    dropoff.getHours() === 0 &&
    dropoff.getMinutes() === 0 &&
    dropoff.getSeconds() === 0 &&
    dropoff.getMilliseconds() === 0
  )
}

export const getFirstValidDatetime = dropoffOffsetInHours => {
  const now = new Date()
  const currentDateTimeWithOffset = addHours(now, dropoffOffsetInHours)

  // Determine the first date available that has at least one valid time slot (before 1:45pm)
  let firstValidDate = currentDateTimeWithOffset
  const currentDateWithOffsetAtLastValidTimeSlot = fromDateTime(
    currentDateTimeWithOffset,
    new Date(0, 0, 0, 13, 45),
  )
  if (currentDateTimeWithOffset > currentDateWithOffsetAtLastValidTimeSlot) {
    firstValidDate = addDays(firstValidDate, 1)
  }

  const startTime = addHours(startOfDay(now), 10) // 10:00
  const hours = times(16, n => addMinutes(startTime, n * 15)) // 10:00 - 13:45

  const firstValidTime = hours.find(
    slot => fromDateTime(firstValidDate, slot) >= currentDateTimeWithOffset,
  )

  return fromDateTime(firstValidDate, firstValidTime)
}

// TODO Some of the dates need to be updated every year
// Eventually we will want to retrieve these from the backend
export const holidays = [
  [0, 1], // January 1st, 2021
  [4, 31], // May 31th, 2021
  [6, 4], // July 4th, 2021
  [6, 5], // July 5th, 2021
  [8, 7], // September 7th, 2020
  [10, 26], // November 26th, 2020
  [10, 27], // November 27th, 2020
  [11, 24], // December 24th, 2020
  [11, 25], // December 25th, 2020
]

export const isDayDisabled = (currentDate, firstValidDateTime) => {
  if (!currentDate || !firstValidDateTime) return true

  const dateAtCurrentTime = fromDateTime(currentDate, firstValidDateTime)

  const before = isBefore(dateAtCurrentTime, firstValidDateTime)
  const sameDay = isSameDay(dateAtCurrentTime, firstValidDateTime)
  const isWeekend = [0, 6].some(dayOfWeek => dateAtCurrentTime.getDay() === dayOfWeek)
  const isHoliday = holidays.some(
    dayMonth =>
      dateAtCurrentTime.getMonth() === dayMonth[0] && dateAtCurrentTime.getDate() === dayMonth[1],
  )

  return (before && !sameDay) || (before && sameDay) || isWeekend || isHoliday
}

export const isValidDateString = (day, format) => {
  const d = parse(day, format, 0)
  return isValid(d)
}

export const toMidnight = date => new Date(date.setHours(0, 0, 0, 0))

export const getCutoff = (dropoff, orderCutoffOffset) => {
  const date = new Date(dropoff.valueOf())

  return new Date(date.setHours(date.getHours() - orderCutoffOffset))
}
