import React, { useEffect } from 'react'
import { Field } from 'formik'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import classnames from 'classnames'

import StripePaymentMethodElement from './StripePaymentMethodElement'
import PaymentMethodItem from './PaymentmethodItem'
import { Box, Typography } from '@material-ui/core'

const PaymentMethodOption = ({ selected, onChangePayment, value, paymentMethod, children }) => (
  <div
    className={classnames('payment-method-option', {
      'payment-method-option--selected': selected,
    })}
    onClick={() => !selected && onChangePayment(value)}
  >
    <div className='payment-method-option__credit-card'>
      <PaymentMethodItem paymentMethod={paymentMethod} newCard={!paymentMethod} />
      {children}
    </div>
  </div>
)

const PaymentMethod = ({
  payments,
  values,
  validateForm,
  sidebar,
  CardElement,
  CardNumberElement,
}) => {
  useEffect(() => {
    if (!values.paymentMethodId) {
      // Ensure form is validated if Add New Card form is selected,
      // otherwise formik.isValid will not be correct
      validateForm()
    }
  }, [values.paymentMethodId])

  return (
    <div className='payment-method'>
      {!sidebar && (
        <div className='payment-method__header'>
          <div className='payment-method__title'>Payment Methods</div>
        </div>
      )}

      {!isEmpty(payments) ? (
        <div className='payment-method__credit-cards'>
          <div
            className={classnames('payment-method__cards-on-file', {
              'payment-method__cards-on-file--sidebar': sidebar,
            })}
          >
            Credit Cards on File:
          </div>

          {map(payments, p => (
            <Field key={p.providerId} value={p.providerId} name='paymentMethodId'>
              {({ form, field }) => (
                <PaymentMethodOption
                  value={p.providerId}
                  onChangePayment={() =>
                    form.setValues({
                      paymentMethodId: p.providerId,
                    })
                  }
                  selected={p.providerId === field.value}
                  paymentMethod={p}
                />
              )}
            </Field>
          ))}

          <Field value={undefined} name='paymentMethodId'>
            {({ form, field }) => {
              const newCardSelected = field.value === undefined
              return (
                <PaymentMethodOption
                  value=''
                  onChangePayment={() => form.setFieldValue(field.name, undefined)}
                  text={<span>+ Add Form of Payment</span>}
                  selected={newCardSelected}
                >
                  {newCardSelected && (
                    <Box mt={3}>
                      <StripePaymentMethodElement
                        CardElement={CardElement}
                        CardNumberElement={CardNumberElement}
                      />
                    </Box>
                  )}
                </PaymentMethodOption>
              )
            }}
          </Field>
        </div>
      ) : (
        <Box mt={3}>
          <Typography variant='overline'>Enter New Card Details Below</Typography>
          <StripePaymentMethodElement
            CardElement={CardElement}
            CardNumberElement={CardNumberElement}
          />
        </Box>
      )}
    </div>
  )
}

export default PaymentMethod
