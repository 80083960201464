import React from 'react'
import { Box, Typography } from '@material-ui/core'

const supportedBrands = ['visa', 'amex', 'mastercard', 'discover']

const getCardSrc = brand =>
  supportedBrands.includes(brand) ? `/images/card_${brand}.png` : `/images/card_etc.svg`

export default function PaymentMethodItem ({ paymentMethod, newCard }) {
  const lastFour = paymentMethod ? (paymentMethod.card ? paymentMethod.card.lastFour : '') : ''
  const brand = paymentMethod ? (paymentMethod.card ? paymentMethod.card.brand : '') : ''
  return (
    <>
      <Box maxWidth='35px' marginRight={3} clone>
        <img style={{ verticalAlign: 'middle' }} src={getCardSrc(brand)} alt='cc' />
      </Box>
      <Box display='inline-block'>
        <span style={{ color: '#999', display: 'inline-block', marginRight: '10px' }}>
          •••• •••• ••••
        </span>
        <Typography color='secondary' component='span'>
          {newCard ? 'New Card' : lastFour}
        </Typography>
      </Box>
    </>
  )
}
