import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { get, find } from 'lodash'

import { ReactComponent as CreditCardIcon } from 'assets/dfo/icon--credit-card.svg'
import DfoAction from 'components/newOrderWorkflow/shared/dfoAction/DfoAction'
import PaymentMethod from 'components/newOrderWorkflow/checkoutPage/checkoutMain/paymentMethod/PaymentMethod'
import RoundButton from 'components/newOrderWorkflow/shared/roundButton/RoundButton'
import {
  AlertErrorMessage,
  AlertSuccessMessage,
} from 'components/newOrderWorkflow/shared/alertMessage/AlertMessage'
import { updateCreditCard, processGroupOrder, getGroupOrder } from 'redux/modules/groupOrder'
import { getPayments } from 'redux/modules/user'
import { selectGroupOrder, selectPayments } from 'redux/selectors'
import { isPaymentError, canUpdatePayment as checkCanUpdatePayment } from 'utils/order'
import { MESSAGE_PROCESSING_ERROR } from 'errors'
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'

import { getCaptcha, createSetupIntent } from 'api'
import PaymentMethodItem from '../../checkoutPage/checkoutMain/paymentMethod/PaymentmethodItem'

const UsedPaymentMethod = ({
  profileData,
  paymentMethod,
  paymentError,
  showPaymentUpdateSuccess,
  charged,
}) => (
  <div className='used-payment-method'>
    <div className='used-payment-method__label'>Card Used for Order:</div>
    {!paymentMethod && profileData.lastFour && (
      <div className='used-payment-method__card'>
        <CreditCardIcon />
        <span>&bull;&bull;&bull;&bull;&nbsp;{profileData.lastFour}</span>
      </div>
    )}

    {paymentMethod && (
      <div className='used-payment-method__card'>
        <PaymentMethodItem paymentMethod={paymentMethod} />
      </div>
    )}
    {paymentError && <AlertErrorMessage>{paymentError}</AlertErrorMessage>}
    {showPaymentUpdateSuccess && (
      <AlertSuccessMessage>
        The credit card for this order has been updated
        {charged ? ' and charged successfully.' : '.'}
      </AlertSuccessMessage>
    )}
  </div>
)

const PaymentMethodForm = ({ payments, onSubmit, submitting, CardNumberElement }) => (
  <Formik
    initialValues={{
      paymentMethodId: get(payments, '[0].providerId'),
    }}
    onSubmit={onSubmit}
    validateOnBlur={false}
    validateOnChange
  >
    {formikProps => (
      <Form>
        <PaymentMethod
          payments={payments}
          {...formikProps}
          CardNumberElement={CardNumberElement}
          sidebar
        />
        <br />
        <RoundButton label='Update Payment Now' submitting={submitting} />
      </Form>
    )}
  </Formik>
)

const Payment = ({
  profileData,
  orderComplete,
  paymentError,
  groupOrder,
  payments,
  getPayments,
  updateCreditCard,
  processGroupOrder,
  getGroupOrder,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [showPaymentMethod, setShowPaymentMethod] = useState(paymentError ? true : false)
  const [paymentUpdateSuccessful, setPaymentUpdateSuccessful] = useState(false)
  const [ccUpdateError, setCcUpdateError] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [chargeCard, setChargeCard] = useState(false)
  const canUpdatePayment = checkCanUpdatePayment(groupOrder)

  useEffect(() => {
    getPayments()
    if (isPaymentError(groupOrder)) {
      setChargeCard(true)
    }
  }, [profileData.stripePaymentMethodId])

  const onSubmit = async (values, actions) => {
    let { paymentMethodId } = values
    const { setFieldError } = actions

    if (paymentMethodId === undefined) {
      try {
        if (!elements || !stripe) {
          setFieldError(
            'paymentMethodId',
            'Payment System not initialized. Please contact support. ',
          )
          setSubmitting(false)
          return
        }

        const captchaToken = await getCaptcha()
        const setupIntentRes = await createSetupIntent({ captchaToken })
        if (setupIntentRes.error) {
          setCcUpdateError(setupIntentRes.error)
          setSubmitting(false)
          return
        }

        const reqBody = {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        }

        const { setupIntent, error } = await stripe.confirmCardSetup(
          setupIntentRes.clientSecret,
          reqBody,
        )
        if (error) {
          setCcUpdateError(error.message)
          setSubmitting(false)
          return
        }

        paymentMethodId = setupIntent.payment_method
      } catch (err) {
        setCcUpdateError(err.message)
        setSubmitting(false)
        return
      }
    }

    const ccProfile = { paymentMethodId }

    setSubmitting(true)
    try {
      await updateCreditCard(groupOrder.id, ccProfile)

      // If the order is in CHARGED_FAILED state
      // Process it right away
      if (isPaymentError(groupOrder)) {
        await processGroupOrder(groupOrder.id)
      }

      await getGroupOrder(groupOrder.id)
      setCcUpdateError(null)
      setPaymentUpdateSuccessful(true)
      setShowPaymentMethod(false)
    } catch (err) {
      setCcUpdateError(MESSAGE_PROCESSING_ERROR)
    } finally {
      setSubmitting(false)
    }
  }

  const toggleButton = paymentError ? null : showPaymentMethod ? (
    <DfoAction onClick={() => setShowPaymentMethod(false)}>Cancel</DfoAction>
  ) : (
    <DfoAction
      onClick={() => {
        setShowPaymentMethod(true)
        setPaymentUpdateSuccessful(false)
        getPayments()
      }}
    >
      Edit
    </DfoAction>
  )

  return (
    <div className='payment'>
      <div className='payment__header'>
        <h2 className='payment__title'>
          {orderComplete ? 'Payment Method Used' : 'Payment Method'}
        </h2>
        {canUpdatePayment && toggleButton}
      </div>

      <UsedPaymentMethod
        profileData={profileData}
        paymentMethod={find(payments, p => p.providerId === profileData.stripePaymentMethodId)}
        paymentError={paymentError && MESSAGE_PROCESSING_ERROR}
        showPaymentUpdateSuccess={paymentUpdateSuccessful && !showPaymentMethod}
        charged={chargeCard}
      />

      {showPaymentMethod && (
        <>
          {payments && (
            <PaymentMethodForm
              ccProfile={profileData}
              payments={payments}
              onSubmit={onSubmit}
              submitting={submitting}
              CardNumberElement={CardNumberElement}
            />
          )}
          {ccUpdateError && (
            <div className='payment__payment-method-warning'>
              <AlertErrorMessage>{ccUpdateError}</AlertErrorMessage>
            </div>
          )}
          {paymentError && (
            <div className='payment__payment-method-warning'>
              <AlertErrorMessage>
                Your credit card will be authorized immediately to ensure on-time delivery.
              </AlertErrorMessage>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  groupOrder: selectGroupOrder(state),
  payments: selectPayments(state),
})

const mapDispatchToProps = {
  getPayments,
  updateCreditCard,
  processGroupOrder,
  getGroupOrder,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Payment)
