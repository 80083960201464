import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import DfoLayout from 'components/newOrderWorkflow/dfoLayout/DfoLayout'
import DfoPageLayout from 'components/newOrderWorkflow/dfoPageLayout/DfoPageLayout'
import OrderManageMain from './orderManageMain/OrderManageMain'
import OrderManageSidebar from './orderManageSidebar/OrderManageSidebar'
import OrderManageHeader from './orderManageHeader/OrderManageHeader'
import { selectCurrentUser, selectGroupOrder } from 'redux/selectors'
import { getLocation } from 'redux/modules/location'
import { getGroupOrder } from 'redux/modules/groupOrder'

import { isPaymentError } from 'utils/order'

class OrderManagePage extends Component {
  state = {
    loading: true,
  }

  async componentDidMount () {
    const { groupOrderId } = this.props.match.params
    const groupOrder = await this.props.getGroupOrder(groupOrderId)

    const locationId = groupOrder.monolithLocationId
    await this.props.getLocation(locationId)

    this.setState({ loading: false })
  }

  render () {
    if (this.state.loading) {
      return null
    }

    const { currentUser, groupOrder } = this.props
    const paymentError = isPaymentError(groupOrder)

    return (
      <DfoLayout currentUser={currentUser} headerProps={{ fixedHeader: true }}>
        <DfoPageLayout
          headerComponent={<OrderManageHeader paymentError={paymentError} />}
          mainComponent={<OrderManageMain />}
          sidebarComponent={<OrderManageSidebar paymentError={paymentError} />}
          inlineMobileSidebar
        />
      </DfoLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  groupOrder: selectGroupOrder(state),
})

const mapDispatchToProps = {
  getGroupOrder,
  getLocation,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OrderManagePage),
)
